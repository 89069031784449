<template>
  <div>
    <h3 class="slide__subtitle">
      <em>{{ articleModel.title }}</em>
    </h3>
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>

    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            <h3>Content Preview</h3>
            <v-card>
              <v-card-text>
                <h2 class="section__title">{{ articleModel.title }}</h2>
                <p>{{ articleModel.content }}</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <h3>List Preview</h3>
            <article-card :article="articleModel"></article-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :to="`${$route.path}/edit`">Edit</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { Article } from "@/models/Article";
import { gql } from "apollo-boost";
import ArticleCard from "@/components/Frontend/Articles/ArticleCard";

export default {
  components: {
    ArticleCard,
  },
  data() {
    return {
      articleModel: new Article(),
      crumbs: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/admin/dashboard",
        },
        {
          text: "Articles",
          disabled: false,
          href: "/admin/articles",
        },
        {
          text: "Article Details",
          disabled: true,
        },
      ],
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    article: {
      query: gql`
        query Article($id: ID!) {
          article(id: $id) {
            id
            title
            content
            featureContent
            featurePicture
            headerPicture
            createdBy
            createdByName
            createdAt
            updatedAt
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        this.articleModel.id = this.article.id;
        this.articleModel.title = this.article.title;
        this.articleModel.content = this.article.content;
        this.articleModel.featureContent = this.article.featureContent;
        this.articleModel.featurePicture = this.article.featurePicture;
        this.articleModel.headerPicture = this.article.headerPicture;
        this.articleModel.createdByName = this.article.createdByName;
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
